<template>
  <div class="register-existing reset-password">
    <div class="container-s">
      <div class="row">
        <div class="col">
          <p>{{ cms.page.fgPleaseEnterNumber }}</p>
          <p>{{ cms.page.fgPleaseEnterEmail }}</p>
          <p>{{ cms.page.fgPleaseChooseANew }}</p>
          <ul>
            <li>{{ cms.page.fg6OrMoreCharacters }}</li>
            <li>
              {{ cms.page.fgAtLeastOneNumerical }}
            </li>
            <li>
              {{ cms.page.fgDoesNotInclude }}
            </li>
          </ul>
        </div>

        <div class="col">
          <form class="form-reset">
            <Input
                type="text"
                v-model.trim="friendNumber"
                :error="v$.friendNumber"
                :label_white="true"
                :altCss="true"
            >
              {{ cms.page.fgFriendNumber }}
            </Input>

            <Input
                type="text"
                v-model.trim="email"
                :error="v$.email"
                :label_white="true"
                :altCss="true"
            >
              {{ cms.page.fgEmail }}
            </Input>

            <Input
                :type="passwordType"
                which="password"
                allowAutofill="new-password"
                v-model="password"
                :error="v$.password"
                @iconClick="handleIconClick"
                :animateIcon="shouldPasswordIconAnimate"
                :invertIconColor=true
                :label_white="true"
                :altCss="true"
            >
              {{ cms.page.fgPassword }}
            </Input>

            <Input
                :type="passwordConfirmType"
                which="passwordConfirm"
                v-model="confirmPassword"
                :error="v$.confirmPassword"
                @iconClick="handleIconClick"
                :animateIcon="shouldPasswordConfirmIconAnimate"
                :invertIconColor=true
                :label_white="true"
                :altCss="true"
                allowAutofill="new-password"
                v-on:keyup.enter="formSubmit"
            >
              {{ cms.page.fgConfirmNewPassword }}
            </Input>

            <div class="input-row">
              <p v-if="formErrors" class="register-error">{{ cms.page.fgErrorsInForm }}</p>
              <div class="" v-if="registerError.length > 0" v-for="error in registerError">
                <p class="register-error">{{ error }}</p>
              </div>
            </div>

            <div class="form-btn">
              <button
                  type="button"
                  class="btn btn-default"
                  v-bind:class="{ 'form-actively-submitting': formActivelySubmitting }"
                  @click="formSubmit"
              >
                {{ cms.page.fgSubmit }}
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Input";
import {mapStores} from "pinia";
import {useAuthStore} from "@/stores/authStore"
import {useCmsStore} from "@/stores/cmsStore";
import {useDesignStore} from "@/stores/designStore";
import useVuelidate from "@vuelidate/core";
import {email, helpers, maxLength, minLength, required, sameAs} from '@vuelidate/validators'
import {useApiCallsStore} from "@/stores/apiCallsStore";
import {inputMixin} from "@/mixins/inputMixin";

export default {
  name: "RegisterExisting",
  mixins: [inputMixin],
  components: {Input},
  setup: () => ({v$: useVuelidate({$lazy: false, $autoDirty: true})}),
  data() {
    return {
      email: '',
      friendNumber: '',
      password: '',
      confirmPassword: '',
      passwordType: "password",
      passwordConfirmType: "password",
      cms: {
        loaded: false, // set to 'true' when CMS content has been returned from API
        options: {
          Common: true,
          Components: [],
          LanguageCode: "",
          PageName: "register_existing",
          MinistryUpdates: false,
        },
        page: {},
      },
      formActivelySubmitting: false,
      formErrors: false,
      registerError: [],
      shouldPasswordIconAnimate: false,
      shouldPasswordConfirmIconAnimate: false,
    };
  },
  computed: {
    ...mapStores(useApiCallsStore, useAuthStore, useCmsStore, useDesignStore),
  },
  async created() {
    this.setContentLoaded(false)
    this.setTitlePageFromCms()
    await this.fetchCmsData()
  },
  methods: {
    async apiCallToRegisterExistingFriend(existingFriend) {
      try {
        return await this.apiCallsStore.RegisterExistingFriend(existingFriend)
            .then(response => {
              return response
            })
      } catch (e) {
        console.error(e)
      }
    },
    buildRegisterExistingFriendJson() {
      const {friendNumber, email, password} = this

      return {
        Email: email,
        MemberNumber: friendNumber,
        Password: password
      }
    },
    async fetchCmsData() {
      try {
        await this.cmsStore
            .getPageAndOrCommonAndOrComponents(this.cms.options)
            .then((results) => {
              if (results) {
                this.cms.page = results;
              } else return false;

              this.setTitlePageFromCms(results)
              this.setContentLoaded(true);
              return true;
            });
      } catch (e) {
        console.error(e);
      }
    },
    async formSubmit() {
      if (this.formActivelySubmitting) return false

      this.setFormErrorMessage(false) // reset form error message
      this.setRegisterErrorMessage("") // reset register error message
      this.setFormBeingActivelySubmitted(true)

      let loader = null
      const isFormValid = await this.v$.$validate()
      try {
        loader = this.$loading.show()
        if (isFormValid) {
          const existingFriend = this.buildRegisterExistingFriendJson()
          const response = await this.apiCallToRegisterExistingFriend(existingFriend)
          await this.handleServerResponse(response)
        } else {
          this.setFormErrorMessage(true)
          return false
        }
      } catch (e) {
        console.error('Error in RegisterExisting.vue, ', e)
      } finally {
        loader.hide()
        this.setFormBeingActivelySubmitted(false)
      }

    },
    async handleServerResponse(res) {
      let errMessage = "There was an issue registering you, please try again."

      if (res && typeof (res) === 'object' && res.cstKey) {
        const newFriend = {
          username: this.email,
          password: this.password
        }
        await this.authStore.login(newFriend)
            .then(() => {
                  this.$router.push({name: 'Friend'})
                },
                error => {
                  this.loading = false;
                  this.message =
                      (error.response && error.response.data && error.response.data.message) ||
                      error.message ||
                      error.toString();
                }
            ).finally(() => {
            })
      }

      if (res && typeof (res) === 'string') {
        errMessage = res
      } else if (res && Array.isArray(res)) {
        this.setFormErrorMessage(true)
        return this.setRegisterErrorMessage(res)
      }

      this.setRegisterErrorMessage([errMessage])
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus;
    },
    setFormBeingActivelySubmitted(status) {
      this.formActivelySubmitting = status
    },
    setFormErrorMessage(status) {
      this.formErrors = status
    },
    setRegisterErrorMessage(message) {
      this.registerError = []
      if (message) {
        message.map(msg => this.registerError.push(msg))
      }
    },
    setTitlePageFromCms(page) {
      const pageTitle = page && page.fgRegisterExistingFriend ? page.fgRegisterExistingFriend : 'Register existing Friend'

      this.designStore.setTitlePage({
        title: pageTitle,
        sub_title: "",
        img_class: "hero-default-desktop",
        btn_history: false,
      });
    },
  },
  validations() {
    const atLeastOneLetter = value => /[a-zA-Z]/.test(value)
    const atLeastOneNumber = value => /[0-9]/.test(value)
    const atLeastSixCharactersLong = value => value.length > 5
    const onlyNumbersAllowed = value => /^[0-9]*$/.test(value)

    return {
      friendNumber: {
        required: helpers.withMessage('Friend Number is required', required),
        maxLengthValue: helpers.withMessage('Friend Number cannot be longer than 10 characters', maxLength(10)),
        minLengthValue: helpers.withMessage('Friend Number cannot be less than 4 characters', minLength(4)),
        onlyNumbersAllowed: helpers.withMessage('Friend number must only be numbers', onlyNumbersAllowed),
      },
      email: {
        required: helpers.withMessage('Email is required', required),
        maxLengthValue: helpers.withMessage('Email cannot be longer than 100 characters', maxLength(100)),
        email,
      },
      password: {
        required: helpers.withMessage('A password is required', required),
        minLengthValue: helpers.withMessage('Password must be at least 6 characters long', atLeastSixCharactersLong),
        atLeastOneLetter: helpers.withMessage('At least one letter is required', atLeastOneLetter),
        atLeastOneNumber: helpers.withMessage('At least one number is required', atLeastOneNumber),
      },
      confirmPassword: {
        required: helpers.withMessage('Confirm your password please', required),
        sameAsRawValue: helpers.withMessage('Passwords must match', sameAs(this.password)),
      },

    }
  }
};
</script>

<style scoped lang="scss">
.register-existing {
  .row {
    display: flex;
    @media screen and (max-width: $md) {
      flex-direction: column;
    }
  }

  .col {
    font-size: 18px;
    line-height: 1.5;
    width: 100%;
    @media screen and (min-width: $md) {
      width: 50%;
    }

    p {
      font-size: 18px;
      line-height: 1.5;
      padding-top: 15px;
      color: #0c2e3c;
      @media screen and (max-width: $md) {
        font-size: 15px;
        padding: 0;
      }
    }

    ul {
      padding: 10px 0 25px 20px;
      color: #0c2e3c;
      line-height: 2;
      @media screen and (max-width: $md) {
        font-size: 15px;
      }
    }
  }

  .col:first-of-type {
    padding: 50px;
  }

  .form-reset {
    width: 100%;
    background-color: $blue;
    padding: 35px 50px;
    @media screen and (min-width: $md) {
      margin: 0 0 0 auto;
      max-width: 701px;
    }

    .form-btn {
      margin-top: 30px;
      @media screen and (max-width: $md) {
        display: flex;
        justify-content: center;
      }
    }

    :deep {
      .input-container {
        .title-label {
          color: $white1;
        }
      }
    }
  }
}

.form-actively-submitting {
  cursor: not-allowed;
}

.register-error {
  color: $white1 !important;
  font-size: 18px;
  font-weight: 500;
  white-space: pre-line;
}
</style>
